.game-upload-wrapper {
  display: flex;
}

.game-upload-panel {
  background-color: #1e1f21;
}

.game-upload-panel img {
  width: 70px;
  height: 100px;
}

.game-upload-action-panel {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  flex: 1;
}
